<template>
  <form
    @submit="handleSubmit"
    class="form-builder"
    :autocomplete="options.autocomplete"
  >
    <div v-if="currentSchema.groups" class="form-builder__content">
      <form-group
        v-for="(group, i) in currentSchema.groups"
        :ref="group.id"
        :key="`${group.id}:${i}`"
        :is-loading="currentSchema.isLoading"
        :data="group"
        :activated-fields="activatedFields"
      />
    </div>

    <div v-if="currentSchema.fields" class="form-builder__content">
      <form-group
        ref="group"
        :is-loading="currentSchema.isLoading"
        :data="{ fields: currentSchema.fields, styleClasses: currentSchema.styleClasses, entity: currentSchema.entity }"
        :activated-fields="activatedFields"
      />
    </div>

    <button type="submit" style="display: none;" />
  </form>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'FormBuilder',
  emits: ['submit'],
  components: {
    FormGroup: defineAsyncComponent(() => import('./components/FormGroup.vue'))
  },
  props: {
    options: {
      type: Object,
      default () {
        return {
          autocomplete: 'off'
        }
      }
    },
    noGroups: {
      type: Boolean,
      default () {
        return false
      }
    },
    activatedFields: {
      type: Array,
      default () {
        return null
      }
    },
    schema: {
      type: Object,
      default () {
        return {
          groups: []
        }
      }
    }
  },
  computed: {
    currentSchema () {
      if (!this.noGroups) {
        return this.schema
      }

      return {
        entity: this.schema.entity,
        styleClasses: 'row',
        fields: this.schema.groups.reduce((acc, group) => {
          return [...acc, ...group.fields]
        }, [])
      }
    }
  },
  methods: {
    handleSubmit (e) {
      e.preventDefault()
      this.$emit('submit', e)

      if (this.schema.onSubmit) {
        this.schema.onSubmit(e)
      }
    }
  }
}
</script>
